import type {FAQContent} from '#/app/components/FAQ';
import {AnalyticsIcon} from '#/app/components/icons/AnalyticsIcon';
import {ControlCenterIcon} from '#/app/components/icons/ControlCenterIcon';
import {CustomerPortalIcon} from '#/app/components/icons/CustomerPortalIcon';
import {DriverAppIcon} from '#/app/components/icons/DriverAppIcon';
import {IntegrationsIcon} from '#/app/components/icons/IntegrationsIcon';
import {RoutingOptimizationIcon} from '#/app/components/icons/RoutingOptimizationIcon';
import {QUOTES} from '#/app/content/quotes';
import type {Quote} from '#/app/types';

export type Feature = {
  color: string;
  ctaText?: string;
  description: string;
  discoverMore?: string;
  icon: any;
  quote?: Quote;
  image: string;
  innerDescription: string;
  innerTitle: string;
  link: string;
  meta: string;
  name: string;
  tagline: string;
  title: string;
};

export const FEATURES: Feature[] = [
  {
    color: 'pink',
    description: `Delight your customers with real-time communication about their delivery. Get real-time feedback and build a lasting customer loyalty.`,
    discoverMore: 'Provide real-time tracking to customers and communicate delivery status updates',
    icon: CustomerPortalIcon,
    image: '/features-customer-portal.jpg',
    innerDescription: 'Provide customers with real-time updates. Communicate seamlessly and build customer loyalty.',
    innerTitle: 'Delight customers with exceptional delivery',
    link: '/features/customer-portal',
    meta: `Delight your customers with real-time communication. Get instant feedback and build a lasting customer loyalty.`,
    name: 'Customer Portal',
    quote: QUOTES['kowalska-91'],
    tagline: 'Communicate with your customers',
    title: 'Connect with\nyour customers',
  },
  {
    color: 'emerald',
    description: `Let our AI engine find the most efficient route. Reduce total delivery costs, CO2 emissions, and fuel consumption.`,
    discoverMore: 'Optimize routes and achieve a cost-efficient and predictable delivery process',
    icon: RoutingOptimizationIcon,
    image: '/features-route-optimization.jpg',
    innerDescription: 'Plan delivery schedules in seconds, optimize delivery routes with a click, and reduce delivery costs by up to 30%.',
    innerTitle: 'Delivery route optimization made simple and efficient',
    // link: '/features/route-optimization',
    link: '/features/routing-optimization',
    meta: `Make delivery route optimization quick, precise, and cost-efficient with Ufleet. Reduce fuel, mileage, and CO2 emissions and support your business growth.`,
    name: 'Route Optimization',
    quote: QUOTES['yotsov-109'],
    tagline: 'Optimize your routes and costs',
    title: 'Optimize your \ndelivery routes',
  },
  {
    color: 'cyan',
    description: `Set delivery sequences. Track delivery status and customer feedback in real time so you can act accordingly when needed.`,
    discoverMore: 'Monitor delivery status in real time and act immediately in case of changes or issues',
    icon: ControlCenterIcon,
    image: '/features-control-center.jpg',
    innerDescription: 'Plan, update and manage sequences. Monitor delivery status and customer feedback in real time.',
    innerTitle: 'Keep deliveries under control',
    link: '/features/control-center',
    meta: `Plan delivery sequences, track delivery status and customer feedback in real time so you can act accordingly when needed.`,
    name: 'Control Center',
    quote: QUOTES['kolev-175'],
    tagline: 'Run your deliveries without surprises',
    title: 'Plan and control\nyour deliveries',
  },
  {
    color: 'amber',
    description: `Streamline dispatch operations in one intuitive web dashboard. Empower drivers with a user friendly mobile app.`,
    discoverMore: 'Empower your drivers to make deliveries faster and easily communicate with customers',
    icon: DriverAppIcon,
    image: '/features-driver-app.jpg',
    innerDescription: 'Let your drivers get real-time updates on deliveries, and communicate easily with dispatchers and customers.',
    innerTitle: 'Empower and manage your delivery drivers',
    link: '/features/driver-app',
    meta: `Streamline dispatch operations in one intuitive web dashboard. Empower drivers with a user friendly mobile app.`,
    name: 'Driver App',
    quote: QUOTES['gadzhev-86'],
    tagline: 'Empower your drivers in the field',
    title: 'Manage\nyour drivers',
  },
  {
    color: 'rose',
    description: `Track delivery analytics and statistical data. Make informed decisions about your strategy and operations.`,
    discoverMore: 'Get insights into your delivery operations and make data-driven decisions about your business',
    icon: AnalyticsIcon,
    image: '/features-analytics.jpg',
    innerDescription:
      'Understand your business better. Use the reports and analytics to build a strategy based on data instead of assumptions.',
    innerTitle: 'Make data-driven decisions',
    link: '/features/analytics',
    meta: `Track delivery analytics and statistical data. Make informed decisions about your strategy and operations.`,
    name: 'Analytics',
    quote: QUOTES['yankov-105'],
    tagline: 'Measure and grow your business',
    title: 'Understand\nyour business',
  },
  {
    color: 'teal',
    description: `Connect your existing ecommerce infrastructure, CRM, or ERP. Explore our developer friendly API for more hands-on implementations.`,
    discoverMore: 'Connect the tools you already use for seamless and integrated operations',
    icon: IntegrationsIcon,
    image: '/features-integrations.jpg',
    innerDescription:
      'You can easily connect your CRM, ERP, or e-commerce platform. Explore the dev-friendly API for hands-on implementation.',
    innerTitle: 'Connect the apps and tools you already use',
    link: '/features/integrations',
    meta: `Connect your existing ecommerce infrastructure, CRM, or ERP. Explore our developer friendly API for more hands-on implementations.`,
    name: 'Integrations',
    quote: QUOTES['bartov-88'],
    tagline: 'Work with your existing tools',
    title: 'Integrate with\nyour tools',
  },
] as const;

export const GeneralFAQ: FAQContent[] = [
  {
    answer: () => (
      <div className="space-y-5">
        <p>
          Actually the differences are semantical and often marketing related. Other commonly used names are delivery management system,
          last mile delivery software, transport planning software and others.
        </p>
        <p>What is important is that Ufleet addresses the three key challenges for every delivery businesses:</p>
        <ul className="ml-5 list-decimal space-y-2 pl-5">
          <li>Optimal routes</li>
          <li>Streamlined field operations</li>
          <li>Happy customers</li>
        </ul>
      </div>
    ),
    question: 'Is Ufleet a route planner or a transport management software (TMS)?',
  },
  {
    answer: () => (
      <div className="space-y-4">
        <p>Ufleet works best in the following industries:</p>
        <ul className="ml-5 list-disc space-y-2 pl-5">
          <li>retail/ecommerce</li>
          <li>grocery, food & beverage</li>
          <li>big & bulky items such as DIY, furniture, or home appliances</li>
        </ul>
        <p>Ufleet will be suboptimal when used for:</p>
        <ul className="ml-5 list-disc space-y-2 pl-5">
          <li>cannabis delivery</li>
          <li>long haul trucking</li>
          <li>rubbish haulage</li>
          <li>professional courier services</li>
        </ul>
        <p>To extract maximum value from Ufleet we suggest operations of at least 5 delivery vehicles or 1,500+ deliveries per month.</p>
      </div>
    ),
    question: 'Is Ufleet the right system for me?',
  },
  {
    answer:
      'Definitely. You can have an in-house fleet, a completely outsourced fleet, or a combination of the two. The only requirement is that the drivers have the mobile app installed and authorized',
    question: 'Can I still use Ufleet if I outsource my deliveries?',
  },
  {
    answer: `Yes, Ufleet integrates with the most popular ecommerce platforms, CRM's, and ERP's. For more control use the Ufleet API. Exporting and importing standard CSV or XLSX files is also a widely used option.`,
    question: 'Can I use Ufleet with my other tools?',
  },
  {
    answer: 'Ufleet is available globally in English, German, Polish, and Bulgarian. Spanish and Chinese are upcoming.',
    question: 'Is Ufleet available in my country?',
  },
  {
    answer: () => (
      <>
        Yes. Have a look at our{' '}
        <a className="_cta text-gray-600 underline" href="https://www.youtube.com/watch?v=l6wFay1JMAw" rel="noreferrer" target="_blank">
          feature video
        </a>{' '}
        or{' '}
        <a className="_cta text-gray-600 underline" href="/pricing">
          get in touch
        </a>{' '}
        for a live demo.
      </>
    ),
    question: 'Can I see Ufleet in action?',
  },
];

export const CalculatorFAQ: FAQContent[] = [
  {
    answer:
      'Several factors contribute to last mile delivery costs, including vehicle maintenance, fuel consumption, driver wages, route planning, and delivery time. External factors like traffic congestion, delivery location density, and customer delivery windows also play a significant role.',
    question: 'What factors contribute to the cost of last mile delivery?',
  },
  {
    answer:
      'Last mile delivery is often the most complex and time-consuming part of the logistics chain. It involves navigating through urban areas, dealing with traffic, and making numerous stops, all of which increase the time and resources needed for each delivery.',
    question: 'Why is last mile delivery more expensive than other logistics stages?',
  },
  {
    answer:
      'Route optimization software reduces costs by creating the most efficient routes, which lowers fuel consumption and saves time. It also helps to manage driver schedules more effectively, leading to better resource utilization and decreased labor costs.',
    question: 'How can route optimization software reduce last mile delivery costs?',
  },
  {
    answer: `Inefficient last mile delivery can lead to higher operational costs, delayed deliveries, and reduced customer satisfaction. This inefficiency can negatively impact a business's reputation, customer loyalty, and ultimately, its bottom line.`,
    question: 'What is the impact of inefficient last mile delivery on a business?',
  },
  {
    answer:
      'Customer expectations for fast, accurate, and convenient delivery can increase the cost of last mile delivery. Meeting these expectations often requires more resources, advanced technology, and efficient logistics planning.',
    question: 'How do customer expectations influence last mile delivery costs?',
  },
] as const;
